import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-white text-lg" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_auth_container = _resolveComponent("auth-container", true)!

  return (_openBlock(), _createBlock(_component_auth_container, {
    backgroundImageSource: './backgroundImage2.png',
    isCentered: true,
    isFullHeight: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["absolute h-10 w-full top-0 left-0 flex items-center justify-center", `${
                _ctx.$route.name?.toString().includes('CustomerAuth')
                    ? 'bg-black-light'
                    : 'bg-primary'
            }`])
      }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$route.name?.toString().includes("CustomerAuth")
                        ? _ctx.$t("customer_login")
                        : _ctx.$t("lauer_login")), 1)
      ], 2),
      _createElementVNode("img", {
        src: './logo.png',
        class: "mb-14 mt-10 h-24 sm:mt-0"
      }, null, 8, _hoisted_2),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["backgroundImageSource"]))
}